import React, { Component } from 'react';
import { Segment, Form, Table } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import { FormSetupError, FormIsLoading, SimpleFormInput, Configurator, remap } from '../Stores/FormStores/FormStore'
import ApplyFeedbackButton from '../Components/ApplyFeedbackButton'


const WifiConfigFormView = observer(({form, onChange}) => (
    <Form>
        <Form.Group widths='equal'>
        <SimpleFormInput
            fluid
            label='Access point name (SSID)'
            value={form.fields.ssid.value}
            error={form.fields.ssid.error}
            name='ssid'
            onChange={onChange}
        />
        <SimpleFormInput
            fluid
            label='Password'
            value={form.fields.password.value}
            error={form.fields.password.error}
            name='password'
            onChange={onChange}
        />
        </Form.Group>
    </Form>
))

const AccessPointDetails = () => (
    <Table basic="very" collapsing>
    <Table.Body>
        <Table.Row>
            <Table.Cell>Access point name</Table.Cell>
            <Table.Cell><strong>{"UnderwaterGPS"}</strong></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Access point password</Table.Cell>
            <Table.Cell><strong>{"waterlinked"}</strong></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>URL for web GUI</Table.Cell>
            <Table.Cell><strong>{"http://192.168.7.1"}</strong></Table.Cell>
        </Table.Row>
    </Table.Body>
    </Table>
)

const WifiConfig = inject("wifiConfigStore")(observer(class WifiForm extends Component {
    componentDidMount() {
        this.props.wifiConfigStore.fetch()
    }
    onChange = (key, val) => {
        this.props.wifiConfigStore.formStore.form.fields.mode.value = val
    }

    render() {
        const wifiConfigStore = this.props.wifiConfigStore;
        if (wifiConfigStore.isLoading) {
            return (
                <FormIsLoading/>
            )
        }
        if (!wifiConfigStore.loadSuccess) {
            return (
                <FormSetupError header="Woops!">Error getting WiFi configuration.</FormSetupError>
            )
        }
        let form = wifiConfigStore.formStore.form;
        const onChangeStore = wifiConfigStore.formStore.onFieldChange;
        return (
            <div>
                <Configurator heading="WiFi configurator"
                    name="mode"
                    help="Click to select if you want the WiFi to be in Access point mode or Client mode."
                    value={form.fields.mode.value}
                    error={form.fields.mode.error}
                    options={[remap("ap", "WiFi Access point"), remap("client", "WiFi Client")]}
                    onChange={this.onChange}/>

                <div style={{paddingTop: "0.9em", paddingBottom: "0.9em"}}>
                {form.fields.mode.value === "client" ? (
                    <WifiConfigFormView form={form} onChange={onChangeStore}/>
                ) : (
                    <AccessPointDetails/>
                )}
                </div>
                <ApplyFeedbackButton
                    type="submit"
                    onClick={() => {wifiConfigStore.store()}}
                    disabled={!form.meta.isValid || wifiConfigStore.isStoring}
                    isStoring={wifiConfigStore.isStoring}
                    storeSuccess={wifiConfigStore.storeSuccess}
                    storeFailure={wifiConfigStore.storeSuccess ? "" : wifiConfigStore.storeError}
                />
            </div>
        )
    }
}))

class Wifi extends Component {
    render() {
        return (
            <Segment>
                <WifiConfig/>
            </Segment>
        )
    }
}

export default Wifi;
