import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { Table, Icon, Segment, Header } from 'semantic-ui-react'
import { Divider, Grid } from 'semantic-ui-react'
import { range } from 'd3-array'
import './Diagnostic.css'
import { GPSDetailsMaster, GPSDetailsLocator } from '../Components/GPSView'
import { superFetch } from '../lib/fetch'
import { formatDecimals } from '../lib/format'
import { Graph } from '../Components/DiagnosticGraph'
import PropTypes from 'prop-types';
import { HelpPopUp } from '../Components/HeplText'
import DiagnosticReport from '../Components/DiagnosticReport'
import { LEDButton } from '../Components/LEDButton';
import {SignalStrengthIcon, NoiseStrengthIllustration} from '../Components/SignalStrength';

export function BucketSampler() {
    // https://github.com/d3fc/d3fc-sample/blob/master/src/bucket.js
    var bucketSize = 10;

    var bucket = (data) => bucketSize <= 1
        ? data.map((d) => [d])
        : range(0, Math.ceil(data.length / bucketSize))
            .map((i) => data.slice(i * bucketSize, (i + 1) * bucketSize));

    bucket.bucketSize = function(x) {
        if (!arguments.length) {
            return bucketSize;
        }

        bucketSize = x;
        return bucket;
    };
    return bucket
}

function killInf(val) {
    // Remove negative infinity from the graph
    if (val < -1e9) {
        return -100
    }
    return val
}


class DiagnosticGraph extends Component {
    render() {
        var ids = [
            [["r1"], ["#0000ff"]],
            [["r2"], ["#00ff00"]],
            [["r3"], ["#ff0000"]],
            [["r4"], ["#000000"]]
        ];
        //Note that the font is constant. Tee bigger the graph the smaller the font will seem.
        return(
            <Graph
                graph={this.props.graph}
                x_scale={this.props.x_scale}
                y_scale={this.props.y_scale}
                ids={ids}
                labelX="Distance (m)"
                labelY="Signal Strength (dBm)"
                //y_distance_to_limit={1}
                //y_rounding={5}
                //x_rounding={10}
            />
        )
    }
}

function downsample(payload, x_scale) {
    // Downsample to 1 entry pr pixel in chart (if needed)
    //var dataPrPixel = payload.length / this.width // Downsample to number of pixels available (responsive)
    var dataPrPixel = payload.length / 400 // Downsample to fixed number of entries

    var r0 = payload.map(function(d) { return d[0] })
    var r1 = payload.map(function(d) { return d[1] })
    var r2 = payload.map(function(d) { return d[2] })
    var r3 = payload.map(function(d) { return d[3] })

    if (dataPrPixel > 1)  {
        // Downsample with BucketSampler
        var bs = BucketSampler()
        var buckets = bs.bucketSize(dataPrPixel)

        r0 = buckets(r0).map(function (d) { return Math.max(...d) })
        r1 = buckets(r1).map(function (d) { return Math.max(...d) })
        r2 = buckets(r2).map(function (d) { return Math.max(...d) })
        r3 = buckets(r3).map(function (d) { return Math.max(...d) })
    } else {
        // Use data without downsampling
        dataPrPixel = 1;
    }

    var geometry = [[], [], [], []]
    for (var index = 0; index < r0.length; index++) {
        const px = index * dataPrPixel * x_scale /*+ x_offset*/
        geometry[0][index] = {px: px, py: killInf(r0[index])}
        geometry[1][index] = {px: px, py: killInf(r1[index])}
        geometry[2][index] = {px: px, py: killInf(r2[index])}
        geometry[3][index] = {px: px, py: killInf(r3[index])}
    }
    return geometry
}

class DiagnosticGraphFetcher extends Component {
    constructor() {
        super()
        this.state = {
            graph: [],
            x_scale: 1,
            y_scale: 1,
            error: "",
        }
    }
    fetch = () => {
        superFetch('/api/graph')
        .then(data => {
            const graph = downsample(data.data, data.x_scale)
            this.setState({graph: graph, x_scale: data.x_scale, y_scale: data.y_scale, error: ""})
        })
        .catch(err => {
            console.log("Error", err)
            this.setState({graph: [], error: err.toString()})
        })

    }
    componentDidMount() {
        this.fetch()
        this.interval = setInterval(this.fetch, 2*1000)
    }
    componentWillUnmount() {
        clearInterval(this.interval)
    }
    render() {
        if (this.state.error) {
            return (
                <div>No data available</div>
            )
        }
        return (
            <div>
            {this.state.graph.length > 0 && (
                <DiagnosticGraph
                    graph={this.state.graph}
                    x_scale={this.state.x_scale}
                    y_scale={this.state.y_scale}
                />
            )}
            </div>
        )

    }
}


const ValidIcon = ({is_valid}) => {
    if (is_valid) {
        return (
            <Icon title="Position calculated is valid" color="green" name="check circle"/>
        )
    }
    return (
        <Icon title="Position calculated is not valid" color="red" name="dont"/>
    )
}

const NoValue = -1000

const LocatorDetails = inject("acousticPositionStore")(observer(({acousticPositionStore}) => {
    const pos = acousticPositionStore
    let _x = pos.x <= NoValue ? "--" : formatDecimals(pos.x, 2)
    let _y = pos.y <= NoValue ? "--" : formatDecimals(pos.y, 2)
    let _z = pos.z <= NoValue ? "--" : formatDecimals(pos.z, 2)
    let _std = pos.std <= NoValue ? "--" : formatDecimals(pos.std, 2)

    return (
    <Table basic="very">
      <Table.Body>
        <Table.Row>
        <Table.Cell>Forward (X)</Table.Cell>
        <Table.Cell textAlign="right"><strong>{_x}</strong> m</Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Right (Y)</Table.Cell>
        <Table.Cell textAlign="right"><strong>{_y}</strong> m</Table.Cell>
        </Table.Row>


        <Table.Row>
        <Table.Cell>Depth (Z)</Table.Cell>
        <Table.Cell textAlign="right"><strong>{_z}</strong> m</Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Estimated accuracy</Table.Cell>
        <Table.Cell textAlign="right"><strong>{_std}</strong> m <HelpPopUp text="Estimation of the accuracy of the current position (standard deviation)"/></Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Position valid</Table.Cell>
        <Table.Cell textAlign="right"><ValidIcon is_valid={pos.position_valid}/></Table.Cell>
        </Table.Row>

      </Table.Body>
    </Table>
    )
}))



const ReceiverDetails = ({id, rssi, nsd, distance, valid}) => {
    let _rssi = rssi <= NoValue ? "--" : formatDecimals(rssi, 0)
    let _nsd = nsd <= NoValue ? "--" : formatDecimals(nsd, 0)
    let _distance = distance <= NoValue ? "--" : formatDecimals(distance, 2)
    return (
        <Table.Row>
        <Table.Cell>{id+1}</Table.Cell>
        <Table.Cell>
            <strong>{_rssi}</strong>&nbsp;dBm&nbsp;
            <SignalStrengthIcon rssi={rssi}/>
        </Table.Cell>
        <Table.Cell>
            <strong>{_nsd}</strong>&nbsp;dBm
            <NoiseStrengthIllustration nsd={nsd}/>
        </Table.Cell>
        <Table.Cell>
            <strong>{_distance}</strong>&nbsp;m
        </Table.Cell>
        <Table.Cell>
            {valid ? (
                // Receiver valid
                <Icon title="Signal received on this Receiver valid" color="green" name="check circle"/>
            ) : (
                // Receiver not valid
                <Icon title="Signal received on this Receiver is not valid" color="red" name="dont"/>
            )}
        </Table.Cell>
        </Table.Row>
    )
}

ReceiverDetails.propTypes = {
    id: PropTypes.number.isRequired,
    rssi: PropTypes.number.isRequired,
    nsd: PropTypes.number.isRequired,
    distance: PropTypes.number.isRequired,
    valid: PropTypes.bool.isRequired,
}

const ReceiverOverview = inject("acousticPositionStore")(observer(({acousticPositionStore}) => {
    const receivers = acousticPositionStore.getDiag()
    return (
        <Table basic="very" textAlign="right">
        <Table.Header>
        <Table.Row>
            <Table.Cell>No. <HelpPopUp text="Receiver number"/></Table.Cell>
            <Table.Cell>Signal strength <HelpPopUp text="Strength of the acoustic signal from the Locator (RSSI - Received Signal Strength Indicator)"/></Table.Cell>
            <Table.Cell>Noise <HelpPopUp text="Measurement of interference from other acoustic transmitters in the frequency band in use."/></Table.Cell>
            <Table.Cell>Distance <HelpPopUp text="Distance from Locator to this Receiver"/></Table.Cell>
            <Table.Cell>Valid <HelpPopUp text="Locator signal sucessfully decoded by this Receiver"/></Table.Cell>
        </Table.Row>
        </Table.Header>
          <Table.Body>
              {receivers.map(d => (
                <ReceiverDetails key={d.id} {...d}/>
              ))}

          </Table.Body>
        </Table>
    )
}))

function Diagnostic() {
    return (
        <Segment>

            <Divider horizontal className="divider-spacing">Diagnostic</Divider>

            <Grid verticalAlign="middle" divided stackable>

            <Grid.Column textAlign="center" width="4">
            <Header as="h5">Locator position</Header>
            <LocatorDetails/>
            </Grid.Column>

            <Grid.Column textAlign="center" width="12">
            <Header as="h5">Receiver details</Header>
            <ReceiverOverview/>
            </Grid.Column>
            </Grid>

            <Divider horizontal className="divider-spacing">Acoustic signal view <HelpPopUp text="Signal strength (dBm) at given distance (meter)"/></Divider>

            <DiagnosticGraphFetcher/>

            <Divider horizontal className="divider-spacing">GPS positions</Divider>

            <Grid divided stackable>
            <Grid.Column textAlign="center" width="8">
            <Header as="h5">Locator GPS position</Header>
            <GPSDetailsLocator/>
            </Grid.Column>

            <Grid.Column textAlign="center" width="8">
            <Header as="h5">Topside housing GPS position</Header>
            <GPSDetailsMaster/>
            </Grid.Column>
            </Grid>

            <Divider horizontal className="divider-spacing">Hardware</Divider>
            <p>Verify active connection to topside by clicking by flashing led on master electronic board.</p>
            <LEDButton/>

            <Divider horizontal style={{paddingTop: "2em"}}>Diagnostic report</Divider>
            <DiagnosticReport/>
        </Segment>
    )
}

export default Diagnostic;
