import { extendObservable } from 'mobx'
import { WifiConfigFormStore } from './FormStores/WifiConfig'
import axios from 'axios'


class WifiConfigStore {
    constructor() {
        extendObservable(this, {
            formStore: new WifiConfigFormStore(),
            loadSuccess: false,
            isLoading: false,
            isStoring: false,
            storeSuccess: false,
            loadingError: "",
            storeError: "",
        });
    }
    fetch() {
        this.isLoading = true
        axios.get('/api/v1/config/wifi')
        .then((response) => {
            this.loadSuccess = true
            this.isLoading = false
            this.loadingError = ""
            this.formStore.form.fields.ssid.value = response.data.ssid
            this.formStore.form.fields.password.value = response.data.password
            this.formStore.form.fields.mode.value = response.data.mode
        })
        .catch((e) => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = e.toString()
            console.log(this.loadingError)
        })

    }
    store() {
        this.isStoring = true
        let data = {
            "ssid": this.formStore.form.fields.ssid.value,
            "password": this.formStore.form.fields.password.value,
            "mode": this.formStore.form.fields.mode.value,
        }
        axios.put('/api/v1/config/wifi', data)
        .then(response => {
            if (!response.data.success) {
                // Get error message from "error" field of the JSON and pass on
                throw new Error(response)
            }
            return response
        })
        .then(response => {
            console.log("stored", response);
            this.storeSuccess = true;
            this.isStoring = false;
            this.storeError = "";
            return response
        })
        .catch((e) => {
            console.log("store error", e.response.data.error.toString());
            this.isStoring = false;
            this.storeSuccess = false;
            this.storeError = e.response.data.error.toString()
        })
    }
}

export default WifiConfigStore
