import React, { Component } from 'react';
import { Segment, Message, Form } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import { FormSetupError, FormIsLoading, SimpleFormInput, Configurator, remap } from '../Stores/FormStores/FormStore'
import ApplyFeedbackButton from '../Components/ApplyFeedbackButton'

const NetworkConfigFormView = observer(({form, onChange}) => (
    <Form>
        <Form.Group widths='equal'>
        <SimpleFormInput
            fluid
            label='IP Address'
            value={form.fields.address.value}
            error={form.fields.address.error}
            name='address'
            onChange={onChange}
        />
        <SimpleFormInput
            fluid
            label='Subnet prefix (typically 24)'
            value={form.fields.prefix.value}
            error={form.fields.prefix.error}
            name='prefix'
            onChange={onChange}
        />
        <SimpleFormInput
            fluid
            label='Gateway'
            value={form.fields.gateway.value}
            error={form.fields.gateway.error}
            name='gateway'
            onChange={onChange}
        />
        <SimpleFormInput
            fluid
            label='DNS'
            value={form.fields.dns.value}
            error={form.fields.dns.error}
            name='dns'
            onChange={onChange}
        />
        </Form.Group>
    </Form>
))

const NetworkConfig = inject("networkConfigStore")(observer(class NetworkForm extends Component {
    componentDidMount() {
        this.props.networkConfigStore.fetch()
    }
    onChange = (key, val) => {
        this.props.networkConfigStore.formStore.form.fields.dhcp.value = val
    }

    render() {
        const networkConfigStore = this.props.networkConfigStore;
        if (networkConfigStore.isLoading) {
            return (
                <FormIsLoading/>
            )
        }
        if (!networkConfigStore.loadSuccess) {
            return (
                <FormSetupError header="Woops!">Error getting network configuration.</FormSetupError>
            )
        }
        let form = networkConfigStore.formStore.form;
        const onChangeStore = networkConfigStore.formStore.onFieldChange;
        return (
            <div>
                <Configurator heading="Network configurator"
                    name="dhcp"
                    help="Click to select if you want to use static IP address or DHCP client."
                    value={form.fields.dhcp.value}
                    error={form.fields.dhcp.error}
                    options={[remap("true", "DHCP Client"), remap("false", "Static")]}
                    onChange={this.onChange}/>

                {form.fields.dhcp.value === "false" && (
                    <div>
                        <br/>
                        <NetworkConfigFormView form={form} onChange={onChangeStore}/>
                    </div>
                )}
                <br/>
                <ApplyFeedbackButton
                    type="submit"
                    onClick={() => {networkConfigStore.store()}}
                    disabled={!form.meta.isValid || networkConfigStore.isStoring}
                    isStoring={networkConfigStore.isStoring}
                    storeSuccess={networkConfigStore.storeSuccess}
                    storeFailure={networkConfigStore.storeSuccess ? "" : networkConfigStore.storeError}
                />
                {networkConfigStore.storeSuccess &&
                <Message info icon="info circle" content="You need to reboot the system for the IP configuration to take affect."/>
                }
            </div>
        )
    }
}))

class Network extends Component {
    render() {
        return (
            <Segment>
                <NetworkConfig/>
            </Segment>
        )
    }
}

export default Network;