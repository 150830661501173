export default [
    {
        version: "3.3.0 (2021-10)",
        changes: [
            "Change IMU handling for better performance (heading now needs to be set)",
            "Improve GUI visuals",
            "Reduce latency in GUI (using websockets)",
            "Change units for signal strength and noise",
            "Change ethernet auto-negotiation to 10/100",
            "Fix position can be invalid after changing locator type."
        ],
        knownissues: [
        ],
    },
    {
        version: "3.2.0 (2020-11)",
        changes: [
            "Add support for Locator-S2/P2",
            "Improve IMU performance",
            "Various bug fixes",
        ],
        knownissues: [
        ],
    },
    {
        version: "3.1.1 (2020-09)",
        changes: [
            "Add factory reset",
            "Improve system status",
            "Various bug fixes",
        ],
        knownissues: [
        ],
    },
    {
        version: "3.1.0 (2020-08)",
        changes: [
            "Initial public release on new hardware platform"
        ],
        knownissues: [
        ],
    },
];
/*
    {
        version: "3.0.1 (2020-07)",
        changes: [
            "Production test (internal)"
        ],
        knownissues: [
        ],
    },
    {
        version: "3.0.0 (2020-05)",
        changes: [
            "Initial release on new hardware platform"
        ],
        knownissues: [
        ],
    },
*/

/*
    {
        version: "2.5.1 (2019-12)",
        changes: [
            "Fix ethernet overheating issue"
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
        ],
    },
    {
        version: "2.5.0 (2019-09)",
        changes: [
            "Improve position accuracy and reduce position latency",
            "Add reflective or open water environment selection",
            "Add fix quality to GPS data",
            "Add support for showing lat/lng in map view",
            "Fix position taking a long time to reach correct position when changing channels between multiple Locator-U1",
            "Fix missing icon",
            "Fix incorrect rotation of range visualization"
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
        ],
    },
    {
        version: "2.3.2 (2019-06)",
        changes: [
            "Fix network configuration cannot be saved",
            "Fix web gui takes a long time to load when not connected to the internet",
            "Improve ethernet stability",
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
    {
        version: "2.3.1 (2019-03)",
        changes: [
            "Add support for Locator-U1",
            "Add support for Locator-P1",
            "Add network configuration",
            "Add warning if receivers or Locator-D1 is not connected",
            "Improve warning messages",
            //"Add support for external PPS source",
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
/*  Steel tank mode - Not publicly released
    {
        version: "2.3.0 (2019-01)",
        changes: [
            "Add configurable ping length",
            "Add configurable speed of sound",
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
/* Locator-P1 advanced settings - Not publicly released
    {
        version: "2.2.0 (2018-12)",
        changes: [
            "Add support for Locator-P1",
            "Add network configuration",
            "Add support for external PPS source",
            "Fix ROV heading being wrong in non-map view",
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },

    {
        version: "2.1.1 (2018-08)",
        changes: [
            "Fix ROV heading missing in non-map view",
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
    {
        version: "2.1.0 (2018-06)",
        changes: [
            "Add support for Locator-S1",
            "Bug fixes",
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
    {
        version: "2.0.0 (2018-04)",
        changes: [
            "Complete rewrite of user interface to improve usability",
            "Improve setup with drag and drop of receivers and range",
            "Improve support for small screens (mobile phones / tablets)",
            "Add range visualization",
            "Add support for imperial/metric selection",
            "Add warning messages to assist correct setup and use",
            "Add points of interest (POI)",
            "Add automatic notification of new releases",
            "Fix incorrect calculation of longitude"
        ],
        knownissues: [
            "IMU heading can be unstable. This affect the position in the map view, but not the relative position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
    {
        version: "1.4.0 (2017-12-12)",
        changes: [
            "Significantly improved performance in tank, pool and other highly reflective environments.",
            "Slightly improved performance on long distance.",
        ],
        knownissues: [
            "Multiple users (or browser windows) editing the configuration screen at the same time can give issues where configurations are not applied correctly.",
            "IMU heading is unstable. This affect the position in the map view, but not the acoustic position.",
            "If receivers are not connected the position is calculated as X: -85.67  Y: -85.67"
        ],
    },
    {
        version: "1.3.0 (2017-11-03)",
        changes: [
            "Add advanced range setting",
            "Add option to allow full-screen in map view",
            "Improve acoustic accuracy",
            "Improve IMU calibration and persist IMU calibration values in non volatime memory.",
            "Fix issue where position requests were not terminated when changing tabs in the GUI",
            "Fix issue where master position was incorrectly returned from /api/v1/position/master when using static or external position",
        ],
        knownissues: [
            "Multiple users (or browser windows) editing the configuration screen at the same time can give issues where configurations are not applied correctly.",
            "IMU heading is unstable. This affect the position in the map view, but not the acoustic position.",
        ],
    },
    {
        version: "1.2.1 (2017-10-02)",
        changes: [
            "Add on-board GPS and IMU",
            "Fix issue where web gui becomes slower after some time",
        ],
        knownissues: [
            "IMU may loose calibration resulting in poor heading accuracy. This affect the position in the map view, but not the acoustic position.",
            "Multiple users (or browser windows) editing the configuration screen at the same time can give issues where configurations are not applied correctly.",
        ],
    },
    {
        version: "1.1.0 (2017-09-01)",
        changes: [
            "Add depth in map view",
            "Add setting to configure number of seconds of the trail to show",
            "Add diagnostic view",
            "Add support for A1 locator",
            "Improve configuration view",
            "Fix issue where top menu is not visible if screen is too small",
            "Fix issue where longitude was limited to +-90 degrees (changed to +-180)",
            "Fix issue where position would stop updating after some time",
            "Fix issue where orientation in master position was always set to 0",
        ],
        knownissues: [
            "On-board GPS and IMU is disabled (static or external is available)",
            "Web gui becomes slower after running for some time",
            "Multiple users (or browser windows) editing the configuration screen at the same time can give issues where configurations are not applied correctly.",
        ],
    },
    {
        version: "1.0.0 (2017-07-06)",
        changes: [
            "Initial release",
        ],
        knownissues: [],
    }
]
*/
